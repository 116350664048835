import { useMemo } from 'react';

export type CsrfToken = {
    csrfmiddlewaretoken: string;
};

/**
 * Get CSRF token.
 *
 * @returns object with csrf token
 */
export function getCsrfToken(): CsrfToken {
    const token: string | undefined =
        document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') ??
        document.querySelector('input[name="csrfmiddlewaretoken"]')?.getAttribute('value') ??
        undefined;
    return {
        csrfmiddlewaretoken: token ?? '',
    };
}

/**
 * Hook CSRF token.
 *
 * @returns object with csrf token
 */
export function useCsrfToken() {
    return useMemo<CsrfToken>(getCsrfToken, []);
}
