import ky from 'ky';

import { API_URL } from '../../modules/core/constants';
import { ssrContext } from '../../modules/core/contexts/GlobalContext';
import { getCsrfToken } from '../../modules/core/hooks/useCsrfToken';

export const clientApi = ky.create({
    prefixUrl: API_URL,
    headers: {
        'content-type': 'application/json',
        'Accept-Language': ssrContext.currentLanguage,
        'X-CSRFToken': getCsrfToken().csrfmiddlewaretoken,
    },
    parseJson: (text) => JSON.parse(text)?.data,
});
