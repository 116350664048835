import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

import { ssrContext } from '../modules/core/contexts/GlobalContext';

/**
 * The translation file path should be the same as the one prefetched in the html section.
 */
const linkTranslation: HTMLLinkElement | null = document.querySelector('link#react-translation');

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n.use(Backend)
    .use(initReactI18next)
    .init({
        lng: ssrContext?.currentLanguage ?? 'en',
        fallbackLng: 'en',
        debug: process.env.NODE_ENV === 'development',
        nsSeparator: false,
        keySeparator: false,
        returnNull: false,
        interpolation: {
            escapeValue: false,
        },
        contextSeparator: '₍ctx⁾',
        pluralSeparator: '₍plu⁾',
        backend: {
            loadPath: linkTranslation?.href ?? '/static/react/locales/{{lng}}/{{ns}}.json',
        },
    });

export default i18n;
